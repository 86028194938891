<template>
  <div>
    <el-upload
      ref="fileRefs"
      class="upload-demo"
      action="none"
      accept=".xls,.xlsx"
      :show-file-list="false"
      :http-request="fileRequestBackId"
    >
    </el-upload>
  </div>
</template>

<script>
import { questionImport } from '@/api/examination'
import to from 'await-to'
export default {
  props: {
    questionStoreId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      id: '',
    }
  },
  watch: {
    questionStoreId(val) {
      if (val !== '') {
        this.id = val
      }
    },
  },
  created() {},
  methods: {
    async fileRequestBackId(file) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      params.append('questionStoreId', this.id)
      const [, err] = await to(questionImport(params))
      loading.close()
      if (err) {
        loading.close()
        return this.$message.warning({ type: 'warning', message: err.msg })
      }
      this.$message({ type: 'success', message: '导入成功' })
      this.$emit('success')
    },
  },
}
</script>

<style scoped></style>
