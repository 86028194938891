<template>
  <div class="randomSetting">
    <el-row :gutter="20" type="flex">
      <el-col :span="6">
        <el-card class="headerNav">
          <div slot="header" class="clearfix">
            <span class="title">随机试卷——考试设置</span>
          </div>
          <div class="setting">
            <div
              v-for="(item, index) in headerData"
              :key="item"
              class="setttingItem"
              :class="{ active: currentIndex == index }"
              @click="changeNav(index)"
            >
              {{ item }}
              <div class="space"></div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card v-if="currentIndex == 0" class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">随机试题范围</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="changeQuestionBank">
              <i class="el-icon-plus"></i>
              从试题库中选择
            </el-button>
          </div>
          <table-list
            class="tableList"
            :loading="loading"
            :data="questionBank"
            :columns="columns(this)"
            :operates="operates(this)"
          ></table-list>
        </el-card>
        <el-card v-if="currentIndex == 0" class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">试卷生成设置</span>
          </div>
          <el-form ref="form" :model="form" label-width="160px">
            <el-form-item prop="randomType">
              <span slot="label">
                <span class="span-box">
                  <span>试卷随机方式</span>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      千人千题试卷：系统为每个考生从考试范围中随机抽取试题组成试卷，每人的试卷题目和顺序都会不同。<br />
                      随机试卷固定顺序：系统会从考试范围中随机抽取试题组成试卷，每位考生都使用同一套试卷，试题顺序也是同样的。<br />
                      随机试卷固定顺序：系统会从考试范围中随机抽取试题组成一套试卷，每位考生都使用同一套试卷，试题顺序会不一样。
                    </div>
                    <i class="el-icon-question"></i>:
                  </el-tooltip>
                </span>
              </span>
              <el-radio-group v-model="form.randomType">
                <el-radio :label="0">千人千题试卷</el-radio>
                <el-radio :label="1">随机试卷固定顺序</el-radio>
                <el-radio :label="2">随机试卷随机顺序</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="抽取试题数量与分数：">
              <div class="pass">
                <span class="passLine">抽取</span>
                <el-input
                  v-model="form.questionTotal"
                  class="input-width"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  clearable
                ></el-input>
                <span class="topic">题</span>
              </div>
              <div class="pass">
                <span class="passLine">设置及格线</span>
                <el-select v-model="form.passLine" clearable>
                  <el-option value="40" label="总分的40%"></el-option>
                  <el-option value="50" label="总分的50%"></el-option>
                  <el-option value="60" label="总分的60%"></el-option>
                  <el-option value="70" label="总分的70%"></el-option>
                  <el-option value="80" label="总分的80%"></el-option>
                  <el-option value="90" label="总分的90%"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <div class="keepBtn">
              <el-button type="primary" size="medium" @click="handleSubmit">
                保存
              </el-button>
            </div>
          </el-form>
        </el-card>
        <el-card v-if="currentIndex !== 0" class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">试卷生成设置</span>
          </div>
          <div class="transfer">
            <el-transfer
              v-model="value"
              :data="mechanismList"
              :props="{ key: 'examFillItemId', label: 'name' }"
              :titles="['可选填写信息', '已选需要的信息']"
              :button-texts="['加入左侧', '加入右侧']"
              filter-placeholder="请输入课程名称"
              @change="handleChange"
            ></el-transfer>
            <div class="transferIcon" @click="customItem"><i class="el-icon-plus"></i>自定义</div>
          </div>
        </el-card>
        <testTime
          v-if="currentIndex !== 0"
          :edit-ststus="editStstus"
          :transfer-select-ids="value"
          :detail="detail"
        ></testTime>
      </el-col>
    </el-row>

    <questionbankList ref="questionbankList" @addClass="addClass"></questionbankList>

    <el-dialog
      title="添加信息"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="customForm" :model="customForm" :rules="rules" label-width="120px">
        <el-form-item label="信息名称" prop="name">
          <el-input v-model="customForm.name" clearable placeholder="请输入信息名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCustomItem('customForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 表格列配置
const columns = () => [
  {
    prop: 'questionStoreName',
    label: '题库名称',
    width: '250px',
  },
  {
    prop: 'questionNum',
    label: '试题库总数',
    width: '200px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import testTime from './components/testTime'
import { randomQuestionsSave, testDetail, addCustom } from '@/api/examination'
import to from 'await-to'
import TableList from '@/components/TableList'
import questionbankList from './components/questionbankList'
import { mapState, mapMutations } from 'vuex'
import * as type from '@/store/action-types'
export default {
  components: {
    TableList,
    testTime,
    questionbankList,
  },
  data() {
    return {
      value: [],
      mechanismList: [],
      list: [],
      operates,
      columns,
      loading: false,
      dialogFormVisible: false,
      headerData: ['试题设置', '考试设置'],
      currentIndex: 0,
      form: {
        randomType: 0,
        questionTotal: '',
        passLine: '',
        examQuestionStoreIdList: [],
        examId: this.$route.query.examId,
      },
      customForm: {
        name: '',
        examId: this.$route.query.examId,
      },
      detail: {},
      navIndex: this.$route.query.navIndex ? this.$route.query.navIndex : '',
      editStstus: this.$route.query.editStstus ? this.$route.query.editStstus : '',
      rules: {
        name: [{ required: true, message: '请输入信息名称', trigger: 'blur' }],
      },
    }
  },
  computed: {
    ...mapState('user', ['questionBank']),
  },
  created() {
    if (this.navIndex !== '') {
      this.currentIndex = Number(this.navIndex)
    }
    this.testDetailData()
  },
  methods: {
    ...mapMutations('user', [type.SET_QUESTION_BANK]),
    changeNav(i) {
      this.currentIndex = i
    },
    handleChange(val) {
      this.value = val
    },
    addClass(val) {
      if (this.list.length !== 0) {
        val.map(i => {
          if (JSON.stringify(this.list).indexOf(i.questionStoreId) == -1) {
            this.list.push(i)
          }
        })
      } else {
        val.map(e => this.list.push(e))
      }
      // val.map(i => {
      //   this.list.map((e, index) => {
      //     if (i.questionStoreId == e.questionStoreId) {
      //       this.list.splice(index, 1)
      //     }
      //   })
      // })
      // this.list = this.list.concat(val)
    },
    async handleSubmit() {
      let num = 0
      // this.list.map(v => {
      //   num += v.questionNum
      // })
      this.questionBank.map(v => {
        num += v.questionNum
      })
      if (this.form.questionTotal == '') {
        return this.$message({ type: 'warning', message: '请填写抽取题数' })
      } else if (this.form.passLine == '') {
        return this.$message({ type: 'warning', message: '请设置及格线' })
      } else if (this.list.length && this.form.questionTotal > num) {
        return this.$message({ type: 'warning', message: '抽取题数不能大于导入试题总数' })
      }
      let arr = []
      this.questionBank.map(i => arr.push(i.questionStoreId))
      this.form.examQuestionStoreIdList = arr
      this.form.examId = this.$route.query.examId
      const [, err] = await to(randomQuestionsSave(this.form))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '保存成功' })
      this.$router.push('list')
    },
    async testDetailData() {
      const [res, err] = await to(testDetail({ examId: this.form.examId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data
      if (this.detail !== null && this.detail.length !== 0) {
        this.form = res.data.questionSet
        this.list = res.data.questionSet.examQuestionStoreIdList
        this.mechanismList = res.data.answerSet.list
        this.mechanismList.map(v => {
          if (v.enable == true) {
            this.value.push(v.examFillItemId)
          }
        })

        //弹框换成页面
        if (this.$route.query.editStstus) {
          this[type.SET_QUESTION_BANK]({ type: 0, list: this.list })
        }
      }
    },
    handleDelete(row, index) {
      // this.list.splice(index, 1)
      this[type.SET_QUESTION_BANK]({ type: 2, index: index })
    },
    customItem() {
      this.dialogFormVisible = true
    },
    async addCustomItem(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const [res, err] = await to(addCustom(this.customForm))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '添加成功' })
          this.mechanismList.push(res.data)
          this.value.push(res.data.examFillItemId)
          this.dialogFormVisible = false
          this.$refs[formName].resetFields()
        }
      })
    },
    changeQuestionBank() {
      // this.$refs.questionbankList.show({ dialogStatus: true })
      this.$router.push({
        path: '/examination/querstionList',
        query: { dialogStatus: true, navIndex: this.navIndex, examId: this.customForm.examId },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.randomSetting {
  .headerNav {
    height: 100%;
    .setting {
      cursor: pointer;
      font-size: 16px;
      .setttingItem {
        padding-left: 20px;
        line-height: 50px;
        position: relative;
        .space {
          position: absolute;
          left: 0;
          top: 13px;
          width: 4px;
          height: 24px;
          background-color: #ff7b33;
          display: none;
        }
      }
      .active {
        color: #ff7b33 !important;
        .space {
          display: block !important;
        }
      }
    }
  }
  .tableList {
    width: 625px;
  }
  .span-box > i {
    margin: 0 5px;
  }
  .pass {
    .passLine {
      margin-right: 13px;
    }
    .topic {
      margin-left: 10px;
    }
    &:last-child {
      margin-top: 20px;
    }
    ::v-deep .el-input {
      width: 118px !important;
    }
  }
  .input-width {
    width: 102px !important;
    height: 40px;
  }
  .text {
    line-height: 20px;
    font-size: 12px;
    margin-left: 100px;
    color: #909399;
  }
  .texts {
    margin-left: 128px;
  }
  .testTime {
    margin-left: 10px;
    .checkItem {
      width: 100%;
      padding: 15px 0;
      display: flex;
      align-items: center;
      .input-width,
      .date {
        margin-left: 16px;
        margin-right: 10px;
      }
      .icon {
        font-size: 14px;
        i {
          color: #b8b8b8;
        }
      }
    }
  }
  .transfer {
    width: 590px;
    position: relative;
    ::v-deep .el-transfer-panel {
      width: 210px;
    }
    ::v-deep .el-transfer-panel__header {
      padding-left: 5px;
      .el-checkbox__label > span {
        display: none;
      }
      .el-checkbox__input {
        // display: none;
      }
    }
    ::v-deep .el-transfer__buttons > .el-button + .el-button {
      margin-left: 0;
    }
    ::v-deep .el-transfer__buttons > .el-button {
      display: block;
    }
    .transferIcon {
      position: absolute;
      right: 5px;
      top: 10px;
      font-size: 14px;
      color: #ff7b33;
      cursor: pointer;
    }
  }
  .keepBtn {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
}
</style>
