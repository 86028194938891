<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">考试时间控制</span>
      </div>
      <div>
        <el-checkbox-group v-model="checkTimeList" class="testTime" @change="handleCheckTimeChange">
          <div class="checkItem">
            <div><el-checkbox label="考试限时"></el-checkbox></div>
            <div>
              <el-input
                v-model="form.answerTotalDuration"
                class="input-width"
                oninput="value=value.replace(/[^\d]/g,'')"
                clearable
                :disabled="isAnswerTotal"
              ></el-input>
            </div>
            <el-select v-model="form.answerTotalDurationUnit" class="sel">
              <el-option :value="1" label="秒"></el-option>
              <el-option :value="2" label="分"></el-option>
              <el-option :value="3" label="小时"></el-option>
            </el-select>
          </div>
          <div class="text">*超时自动交卷</div>
          <div class="checkItem">
            <div><el-checkbox label="开始时间"></el-checkbox></div>
            <el-date-picker
              v-model="form.examStartTime"
              type="datetime"
              placeholder="选择日期时间"
              class="date"
              :disabled="isAnswerStart"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div class="checkItem">
            <div><el-checkbox label="结束时间"></el-checkbox></div>
            <el-date-picker
              v-model="form.examEndTime"
              type="datetime"
              placeholder="选择日期时间"
              class="date"
              :disabled="isAnswerEnd"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div class="checkItem">
            <div><el-checkbox label="单题限制"></el-checkbox></div>
            <div>
              <el-input
                v-model="form.answerSingleDuration"
                class="input-width"
                oninput="value=value.replace(/[^\d]/g,'')"
                clearable
                :disabled="isAnswerSingle"
              ></el-input>
            </div>
            <div class="icon">秒</div>
          </div>
          <div class="text">
            设置后没题作答时间将被限制，超过时间将会自动下一题，答过的试题不能返回修改。
            如果填写0秒，答题单个不做时间限制，但答过的试题不能返回修改。
          </div>
        </el-checkbox-group>
      </div>
    </el-card>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">答题控制</span>
      </div>
      <div>
        <el-checkbox-group v-model="checkAnswerList" class="testTime" @change="changeAnswer">
          <div class="checkItem">
            <div>
              <el-checkbox label="答题次数限制"></el-checkbox>
            </div>
            <div>
              <el-input
                v-model="form.answerMaxTimes"
                class="input-width"
                clearable
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isAnswerTimes"
              ></el-input>
            </div>
            <div class="icon">次</div>
          </div>
          <div class="text texts">
            同一个账号对考试只能作答的次数，默认只能作答一次
          </div>
          <div v-if="!testType" class="checkItem">
            <el-checkbox label="答题后立即出现答案和解析"></el-checkbox>
          </div>
          <div class="checkItem">
            <el-checkbox label="记录答题时的IP"></el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div class="keepBtn">
        <el-button type="primary" @click="save">
          保存
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { randomTestSave } from '@/api/examination'
import to from 'await-to'
export default {
  props: {
    testType: {
      //试卷类型 0为随机 1为自定义
      type: Number,
      default: null,
    },
    editStstus: {
      type: String,
      default: '',
    },
    transferSelectIds: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        answerTotalDuration: '',
        answerTotalDurationUnit: 2,
        answerMaxTimes: '',
        examStartTime: '',
        examEndTime: '',
        answerSingleDuration: '',
        isAnswerTimes: false,
        answerAppear: false,
        recordIp: false,
        isAnswerTotal: false,
        isAnswerStart: false,
        isAnswerEnd: false,
        isAnswerSingle: false,
        examId: this.$route.query.examId,
        ids: this.transferSelectIds ? this.transferSelectIds : [],
      },
      isAnswerTimes: true,
      isAnswerTotal: true,
      isAnswerStart: true,
      isAnswerEnd: true,
      isAnswerSingle: true,
      checkList: [],
      checkTimeList: [],
      checkAnswerList: [],
      timeData: [
        { lable: '考试限时', status: true },
        { lable: '开始时间', status: true },
        { lable: '结束时间', status: true },
        { lable: '单题限制', status: true },
      ],
    }
  },
  watch: {
    transferSelectIds(val) {
      this.form.ids = val
    },
    detail(val) {
      if (val && JSON.stringify(val) !== '{}') {
        this.getData()
      }
    },
    deep: true,
  },
  created() {
    if (this.detail && JSON.stringify(this.detail) !== '{}') {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.form = Object.assign({}, this.detail.answerSet)
      this.form.ids = this.transferSelectIds
      this.form.answerTotalDurationUnit = 2
      this.form.examId = this.$route.query.examId
      if (this.form.isAnswerTotal) {
        this.isAnswerTotal = false
        this.checkTimeList.push('考试限时')
      }
      if (this.form.isAnswerStart) {
        this.isAnswerStart = false
        this.checkTimeList.push('开始时间')
      }
      if (this.form.isAnswerEnd) {
        this.isAnswerEnd = false
        this.checkTimeList.push('结束时间')
      }
      if (this.form.isAnswerSingle) {
        this.isAnswerSingle = false
        this.checkTimeList.push('单题限制')
      }
      if (this.form.answerAppear) {
        this.answerAppear = false
        this.checkAnswerList.push('答题后立即出现答案和解析')
      }
      if (this.form.isAnswerTimes) {
        this.isAnswerTimes = false
        this.checkAnswerList.push('答题次数限制')
      }
      if (this.form.recordIp) {
        this.recordIp = false
        this.checkAnswerList.push('记录答题时的IP')
      }
    },
    handleCheckTimeChange() {
      this.isAnswerTotal = true
      this.form.isAnswerTotal = false
      this.isAnswerStart = true
      this.form.isAnswerStart = false
      this.isAnswerEnd = true
      this.form.isAnswerEnd = false
      this.isAnswerSingle = true
      this.form.isAnswerSingle = false
      this.timeData.map(v => {
        v.status = true
        this.checkTimeList.map(e => {
          if (v.lable == e) {
            v.status = false
          }
          if (e == '考试限时') {
            this.isAnswerTotal = false
            this.form.isAnswerTotal = true
          } else if (e == '开始时间') {
            this.isAnswerStart = false
            this.form.isAnswerStart = true
          } else if (e == '结束时间') {
            this.isAnswerEnd = false
            this.form.isAnswerEnd = true
          } else {
            this.isAnswerSingle = false
            this.form.isAnswerSingle = true
          }
        })
      })
    },
    changeAnswer() {
      this.isAnswerTimes = true
      this.form.isAnswerTimes = false
      this.answerAppear = true
      this.form.answerAppear = false
      this.recordIp = true
      this.form.recordIp = false
      this.checkAnswerList.map(v => {
        if (v == '答题次数限制') {
          this.isAnswerTimes = false
          this.form.isAnswerTimes = true
          console.log(11)
        } else if (v == '答题后立即出现答案和解析') {
          this.answerAppear = false
          this.form.answerAppear = true
        } else if (v == '记录答题时的IP') {
          this.recordIp = false
          this.form.recordIp = true
        }
      })
    },
    async randomTestSaveData() {
      const [, err] = await to(randomTestSave(this.form))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$router.push('list')
    },
    save() {
      if (this.form.answerTotalDuration == '' && this.form.isAnswerTotal) {
        return this.$message({ type: 'warning', message: '请输入考试限时时间' })
      } else if (this.form.examStartTime == '' && this.form.isAnswerStart) {
        return this.$message({ type: 'warning', message: '请输入开始时间' })
      } else if (this.form.examEndTime == '' && this.form.isAnswerEnd) {
        return this.$message({ type: 'warning', message: '请输入结束时间' })
      } else if (this.form.answerSingleDuration == '' && this.form.isAnswerSingle) {
        return this.$message({ type: 'warning', message: '请输入单题限时时间' })
      } else if (this.form.answerMaxTimes == '' && this.form.isAnswerTimes) {
        return this.$message({ type: 'warning', message: '请输入答题次数' })
      } else {
        if (!this.testType) {
          this.randomTestSaveData()
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.sel {
  ::v-deep .el-input__inner {
    width: 76px;
    border: none;
  }
}
.input-width {
  width: 102px;
  height: 40px;
}
.templateBtn {
  margin-top: 12px;
}
.span-box > i {
  margin: 0 5px;
}
.pass {
  margin-top: 24px;
  .passLine {
    margin-right: 13px;
  }
}
.text {
  line-height: 20px;
  font-size: 12px;
  margin-left: 100px;
  color: #909399;
}
.texts {
  margin-left: 128px;
}
.testTime {
  margin-left: 10px;
  .checkItem {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    .input-width,
    .date {
      margin-left: 16px;
      margin-right: 10px;
    }
    .icon {
      font-size: 14px;
      i {
        color: #b8b8b8;
      }
    }
  }
}
.keepBtn {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
</style>
