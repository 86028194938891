<template>
  <div>
    <!-- <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <table-list
        class="tableList"
        :title="tableTitle"
        :loading="loading"
        :search-form="searchForm"
        :data="list"
        :btns="btns(this)"
        :options="{ selection: true }"
        :columns="columns(this)"
        :operates="operates(this)"
        :pager="pager"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-dialog> -->
    <table-list
      v-if="listStatus == 0 || this.$route.query.dialogStatus"
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm"
      :data="list"
      :btns="btns(this)"
      :options="{ selection: true, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <importTest
      ref="importTest"
      :question-store-id="questionStoreId"
      @success="questionListData(listStatus)"
    ></importTest>

    <el-dialog
      title="创建题库名称"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="题库名称" prop="questionStoreName">
          <el-input
            v-model="form.questionStoreName"
            clearable
            minlength="2"
            maxlength="18"
            placeholder="请输入2-18字题库名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addQuestionbankList('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//表格标题
const tableTitle = '试题库列表'
// 搜索框配置
const searchForm = [
  {
    label: '题库名称',
    prop: 'questionStoreName',
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '确定加入',
    type: 'primary',
    method: _this.handleAddSure,
    isShow: _this.addStatus,
  },
  {
    label: '创建题库名称',
    type: 'primary',
    method: _this.handleCreated,
    isShow: _this.status,
  },
  {
    label: '下载导入模板',
    type: 'primary',
    method: _this.handleDowload,
    isShow: _this.status,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'questionStoreName',
    label: '题库名称',
  },
  {
    prop: 'questionTypeMuster',
    label: '试题包含类型',
  },
  // {
  //   prop: 'bindCourseName',
  //   label: '属于的课程名称',
  // },
  {
    prop: 'questionNum',
    label: '题目数量',
    align: 'right',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '查看详情',
    method: _this.handleLook,
  },
  {
    label: '导入试题',
    method: _this.handleImport,
    isShow: row => {
      return row.status
    },
  },
]
import importTest from './importTest'
import TableList from '@/components/TableList'
import { questionList, questionDownload, createdQuestionBank } from '@/api/examination'
import to from 'await-to'
import * as type from '@/store/action-types'
import { mapMutations } from 'vuex'
export default {
  components: {
    TableList,
    importTest,
  },
  props: {
    listStatus: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      addStatus: null,
      status: null,
      dialogTitle: '试题库列表',
      loading: false,
      dialogVisible: false,
      list: [],
      tableTitle,
      searchForm,
      btns,
      columns,
      operates,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
      questionStoreId: null,
      dialogFormVisible: false,
      form: {
        questionStoreName: '',
      },
      rules: {
        questionStoreName: [
          { required: true, message: '请输入创建课程题库名称', trigger: 'blur' },
          { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    if (this.listStatus == 0) {
      this.questionListData(this.listStatus)
    }
  },
  methods: {
    ...mapMutations('user', [type.SET_QUESTION_BANK]),
    show(status = {}) {
      this.questionListData(status)
    },
    async questionListData(status) {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(questionList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
      if (status && status.dialogStatus) {
        this.dialogVisible = true
        this.status = status.dialogStatus
        this.list.map(v => {
          this.$set(v, 'status', false)
        })
      }
      if (status == 0) {
        this.addStatus = true
        this.list.map(v => {
          this.$set(v, 'status', true)
        })
      }
    },
    handleAddSure() {
      if (this.select.length == 0) {
        return this.$message({ type: 'warning', message: '请选择操作的试题' })
      }
      // this.$emit('addClass', this.select)
      // this.dialogVisible = false

      //弹框换成页面
      this[type.SET_QUESTION_BANK]({ type: 1, list: this.select })
      this.$router.push({
        path: '/examination/random',
        query: { navIndex: this.$route.query.navIndex, examId: this.$route.query.examId },
      })
    },
    handleCreated() {
      this.dialogFormVisible = true
    },
    async addQuestionbankList(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const [, err] = await to(createdQuestionBank(this.form))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '添加成功' })
          this.dialogFormVisible = false
          this.$refs[formName].resetFields()
          this.questionListData(this.listStatus)
        }
      })
    },
    async handleDowload() {
      const [res, err] = await to(questionDownload({ responseType: 'blob' }))
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '题库模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async handleImport(row) {
      this.$refs['importTest'].$refs['fileRefs'].$refs['upload-inner'].handleClick()
      this.questionStoreId = row.questionStoreId
    },
    handleLook(row) {
      this.$router.push({
        path: 'questionBankDetail',
        query: { questionStoreId: row.questionStoreId },
      })
    },
    handleSelectionChange(val) {
      this.select = val
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.questionListData(this.dialogStatus ? this.dialogStatus : this.listStatus)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.questionListData(this.dialogStatus ? this.dialogStatus : this.listStatus)
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.questionListData(this.dialogStatus ? this.dialogStatus : this.listStatus)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.table-list {
  .el-table th > .cell {
    padding-left: 14px;
  }
}
</style>
